.page-loader-container {
    position: fixed; /* Fissa il loader in alto a sinistra dello schermo */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Assicurati che il loader sia sopra gli altri contenuti */
}

.page-loader {
    text-align: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Sfondo semitrasparente */
    z-index: -1; /* Posiziona l'overlay dietro al loader */
}
